<template>
    <div class="bg-[#02130E] fixed w-full h-full">
        <div class="login-form">
            <div class="w-[125px] select-none">
                <img src="@/assets/logo.svg">
            </div>
            <div>
                <input 
                    :class="{
                        'login-form-input-error' : v$.adminLogin.$error,
                        'login-form-input' : !v$.adminLogin.$error
                        }" 
                    @blur="v$.adminLogin.$touch" 
                    v-model="authData.adminLogin" 
                    placeholder="Логін"
                >
            </div>
            <div>
                <input 
                    :class="{
                        'login-form-input-error' : v$.adminPassword.$error,
                        'login-form-input' : !v$.adminPassword.$error
                        }" 
                    @blur="v$.adminPassword.$touch" 
                    v-model="authData.adminPassword" 
                    placeholder="Пароль"
                    type="password"
                >
            </div>
            <div>
                <button @click="processAuthorization" class="login-form-button select-none">Авторизуватись</button>
            </div>
        </div>
    </div>

    <div class="bg-[#02130E] fixed h-full w-full">
        <div>
            <img src="@/assets/login_bg.svg" class="absolute top-[-167px] w-full scale-[1.4] h-full">
        </div>
        <div class="flex justify-center absolute top-[20px] left-[50%] translate-x-[-50%]">
            <img src="@/assets/sportkit_logo.svg" alt="">
        </div>
        <div class="mt-[84px] flex flex-col align-middle items-center justify-center w-full lg:w-[400px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            <div class="flex justify-center">
                <img src="@/assets/logo.svg" class="logo w-[155px]" >
            </div>
            <div class="flex justify-center w-10/12 text-[#D8E2DC] text-[21px] mb-[18px] mt-[45px]">
                Hello admin!
            </div>
            <div 
                :class="{
                        'login-form-input-error' : v$.adminLogin.$error,
                        'login-form-input' : !v$.adminLogin.$error
                        }" 
            >
                <input 
                    v-model="authData.adminLogin" 
                    type="text" 
                    @blur="v$.adminLogin.$touch"  
                    @focus="removePlaceholderLogin" 
                    id="loginInput" 
                    class="input-text bg-transparent text-start placeholder:text-center rounded-[9px] outline-none text-[#D8E2DC] font-medium placeholder:font-medium pl-[10px] w-full placeholder-[#8C8C8C] placeholder:text-[13px] placeholder:relative pr-[20px]" 
                    placeholder="логін"
                >
            </div>
            <div 
                :class="{
                        'login-form-input-error' : v$.adminPassword.$error,
                        'login-form-input' : !v$.adminPassword.$error
                        }"    
            >
                <input 
                    v-model="authData.adminPassword" 
                    type="password" 
                    @blur="v$.adminPassword.$touch"  
                    @focus="removePlaceholderPassword" 
                    id="passwordInput" 
                    class="input-text bg-transparent placeholder:text-center text-start rounded-[9px] outline-none text-[#D8E2DC] font-medium placeholder:font-medium w-full pl-[10px] placeholder-[#8C8C8C] placeholder:relative placeholder:text-[13px]" 
                    placeholder="пароль"
                >
            </div>
            <div class="flex justify-center w-[90%] mt-[10px] mb-[33px]">
                <button @click="processAuthorization" class="bg-[#D8E2DC] w-[355px] border-[1px] border-[#D8E2DC] rounded-[9px] items-center px-[13px] flex h-[40px] justify-center mb-[16px]">
                    <div class="w-full text-[20px] text-black font-extrabold">Log in</div>

                </button>
            </div>
            <!-- <div>
                <img src="@/assets/banner.jpg" alt="">
            </div> -->
            
            <div class="">
                <!-- <div class="text-[#D8E2DC] text-[12px] text-center lg:mx-0 w-[355px] mb-[35px] mx-10">
                    Привіт!<br>Це віртуальний кабінет для гравців ФК Минай, якщо у тебе виникли проблеми з доступом, звернись до свого тренера
                </div> -->
                <div class="text-[#fff] text-[11px] opacity-50 text-center mt-[120px] w-full">
                    © 2023 ALL RIGHTS RESERVED
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive, onMounted } from 'vue'
    import { getTokens } from '@/services/apiRequests'
    import { useRouter } from 'vue-router'
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    export default {
        setup() {
            const router = useRouter()

            const authData = reactive({
                adminLogin: '',
                adminPassword: ''
            })

            const rules = {
                adminLogin: { required },
                adminPassword: { required }
            }

            const v$ = useVuelidate(rules, authData)

            const processAuthorization = async () => {
                const tokensSetFlag = await getTokens(authData.adminLogin, authData.adminPassword)
                if (tokensSetFlag) {
                    localStorage.adminLogin = authData.adminLogin
                    localStorage.adminPassword = authData.adminPassword
                    router.go()
                } else {
                    authData.adminLogin = ''
                    authData.adminPassword = ''
                }
            }

            onMounted(() => {
                document.addEventListener('keydown', async function(event) {
                    if (event.key === 'Enter') {
                        await processAuthorization()
                    }
                })
            })

            return {
                v$,
                authData,
                processAuthorization,
            }
        }
    }
</script>

<style lang="scss">
    .login-form {
        @apply flex flex-col items-center align-middle justify-center absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%];
    }

    .login-form-input {
        @apply bg-[#474849] bg-opacity-20 rounded-[9px] items-center px-[13px] border-[1px] border-[#D8E2DC] flex h-[44px] justify-center w-[90%] mb-[16px];
    }

    .login-form-input-error {
        @apply bg-[#FA1367] bg-opacity-20 rounded-[9px] items-center px-[13px] border-[1px] border-[#FA1367] flex h-[44px] justify-center w-[90%] mb-[16px];
    }

    .login-form-button {
        @apply px-2 py-1 w-[250px] rounded-md bg-[#F9245A] text-white font-semibold;
    }
</style>